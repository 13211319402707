import * as Segment from '@yoursurprise/segment-analytics';
import fetch from '../../api/fetch';

const { ConsentServiceFactory } = Segment;

const wasTrackingConsentGranted = async (): Promise<boolean> => {
    try {
        return await ConsentServiceFactory.create('consent', fetch).wasConsentGranted();
    } catch (error) {
        return false;
    }
};

export default wasTrackingConsentGranted;
